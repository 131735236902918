import { React, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { withRouter } from 'react-router'

import { Card, Page, Layout } from '@shopify/polaris'

import withDriveLoadStatus from '../hoc/withdriveloadstatus'
import { useNavigate, useParams } from 'react-router'

const LoadImagesScreen = (props) => {
  const logsReference = useRef(null)
  const [followLogs, setFollowLogs] = useState(true)
  const { shop } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (followLogs) {
          logsReference.current.scrollTop = logsReference.current.scrollHeight
        }
      }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [followLogs])

  return (
    <Page
      breadcrumbs={[{ content: 'Home', onAction: () => navigate(`/${shop}/`) }]}
      title='Uploading images'
    >
      <Layout>
        <Layout.Section>
          <Card
            title="Logs"
            sectioned={true}
            actions={[
              {
                content: followLogs ? 'Stop following logs' : 'Follow logs',
                onClick: () => setFollowLogs(!followLogs)
              }
            ]}
          >
            <pre style={{ overflow: 'auto', maxHeight: '400px' }} ref={logsReference} >
              {
                props.logs.map((item, index) => <p key={index}>{item}</p>)
              }
            </pre>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

LoadImagesScreen.propTypes = {
  logs: PropTypes.array,
  history: PropTypes.object
}

const DriveLoadImagesScreen = withDriveLoadStatus(LoadImagesScreen)

export default DriveLoadImagesScreen
