import React from 'react'
import uuid from 'react-uuid'

import { Page, Button } from '@shopify/polaris'
import { useNavigate, useParams } from 'react-router'

const UploadTypeSelector = () => {
  const navigate = useNavigate()
  const { shop } = useParams()

  const handleCreateFromPC = () => {
    navigate(`/${shop}/upload/${uuid()}/load-files`)
  }

  return (
    <Page
      breadcrumbs={[{ content: 'Products', onAction: () => { navigate(-1) } }]}
      title="Create images upload"
    >
      <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '300px' }} >
        <div>
          <Button primary onClick={handleCreateFromPC} fullWidth >From files in my computer</Button>
        </div>
        <div style={{ marginTop: '10px' }} >
          <Button disabled fullWidth >From Google Drive account (soon)</Button>
        </div>
      </div>
    </Page>

  )
}

export default UploadTypeSelector
