import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'
import { getSessionToken } from '@shopify/app-bridge-utils'

const config = {
  apiKey: 'f991dbea670fd902b6fab9c20dc6c7cd',
  host: new URLSearchParams(window.location.search).get('host'),
  forceRedirect: true
}

// local config
// const config = {
//   apiKey: '35c707259c2e9ddd9eeaabcdba13e7ca',
//   host: new URLSearchParams(window.location.search).get('host'),
//   forceRedirect: true
// }

class UploadsRepo {
  constructor (shop, apiURL) {
    this.apiURL = apiURL
    this.shop = shop
    this.app = createApp(config)
    this.redirect = Redirect.create(this.app)
  }

  async addSessionHeaders (headers) {
    const token = await getSessionToken(this.app)
    return {
      ...headers,
      'X-Shopify-Session-Token': token
    }
  }

  async list () {
    const url = new URL(`/${this.shop}/uploads`, this.apiURL)
    const headers = await this.addSessionHeaders({})
    const response = await fetch(url, { headers })
    return await response.json()
  }

  async uploadFiles (uploadID, files, progressHandler) {
    const chunkSize = 6000000
    const uploadChunk = async (file, i) => {
      const start = i * chunkSize
      const end = Math.min(start + chunkSize, file.size)
      const blob = file.slice(start, end)
      const formData = new FormData()

      formData.append('file', blob, file.name)

      try {
        const url = new URL(`/${this.shop}/uploads/${uploadID}/files`, this.apiURL)
        const headers = await this.addSessionHeaders(
          { 'Content-Range': `bytes ${start}-${end}/${file.size}` }
        )
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
          headers
        })

        if (!response.ok) {
          await uploadChunk(file, i)
          return
        }

        progressHandler(parseInt((end / file.size) * 100))
      } catch (ex) {
        await uploadChunk(file, i)
      }
    }

    await Promise.all(
      files.map(async (file) => {
        const totalChunks = file.size / chunkSize

        for (let i = 0; i < totalChunks; i++) {
          await uploadChunk(file, i)
        }
        return window.URL.createObjectURL(file)
      })
    )
  }

  async status (uploadID) {
    const url = new URL(`/${this.shop}/uploads/${uploadID}/status`, this.apiURL)
    const headers = await this.addSessionHeaders({})
    const response = await fetch(url, { headers })
    return await response.json()
  }

  async init (uploadID) {
    const url = new URL(`/${this.shop}/uploads/${uploadID}/start`, this.apiURL)
    const headers = await this.addSessionHeaders({})
    const response = await fetch(url, { method: 'POST', headers })
    return await response.json()
  }

  async initOLD (shopifyConfig, driveConfig) {
    const headers = await this.addSessionHeaders({
      'Content-Type': 'application/json'
    })
    const response = await fetch(`${this.apiURL}/load/init?user_id=${this.shop}`, {
      method: 'POST',
      body: JSON.stringify({
        shopifyConfig,
        driveConfig
      }),
      headers
    })

    const jsonData = await response.json()
    return jsonData
  }

  async validatePayment () {
    const headers = await this.addSessionHeaders({})
    const response = await fetch(
      `${this.apiURL}/${this.shop}/payment`,
      {
        method: 'GET',
        headers
      }
    )
    const jsonData = await response.json()
    return jsonData.hasValidPayment
  }

  async createPayment () {
    const headers = await this.addSessionHeaders({})
    const response = await fetch(
      `${this.apiURL}/${this.shop}/payment`,
      {
        method: 'POST',
        headers
      }
    )
    const jsonData = await response.json()
    this.redirect.dispatch(
      Redirect.Action.REMOTE, jsonData.application_charge.confirmation_url
    )
  }
}

export default UploadsRepo
