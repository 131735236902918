import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import UploadsRepo from '../services/uploadsrepo'
import { API_URL } from '../services/globals'

const withUploadsLoader = (WrapepdComponent) => {
  const Component = (props) => {
    const { shop } = useParams()
    const uploadsRepo = new UploadsRepo(shop, API_URL)
    const [isLoading, setIsLoading] = useState(true)
    const [uploads, setUploads] = useState([])
    const [validPayment, setValidPayment] = useState(false)

    useEffect(() => {
      const loadUploads = async () => {
        const response = await uploadsRepo.list()
        const validPayment = await uploadsRepo.validatePayment()

        setIsLoading(false)
        setUploads(response.uploads)
        setValidPayment(validPayment)
      }
      loadUploads()
    }, [])

    return (
      <WrapepdComponent {...props} isLoading={isLoading} uploads={uploads} validPayment={validPayment} createPayment={async () => await uploadsRepo.createPayment()} ></WrapepdComponent>
    )
  }

  return Component
}

export default withUploadsLoader
