import { Page, DropZone, Stack, Thumbnail, Caption, DisplayText } from '@shopify/polaris'
import { NoteMinor } from '@shopify/polaris-icons'
import { PropTypes } from 'prop-types'

import { useNavigate, useParams } from 'react-router'
import React, { useState, useCallback } from 'react'
import UploadsRepo from '../services/uploadsrepo'
import { API_URL } from '../services/globals'

function ImageDropZone ({ onFilesChange, disabled }) {
  const [files, setFiles] = useState([])

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      setFiles((files) => {
        const _files = [...files, ...acceptedFiles]
        onFilesChange(_files)
        return _files
      })
    }, []
  )

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .zip, we will decompress it and upload all images. All non images will be ignored." />
  )

  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={NoteMinor}
          />
          <div>
            {file.name} <Caption>{file.size} bytes</Caption>
          </div>
        </Stack>
      ))}
    </Stack>
  )

  return (
    <DropZone
      onDrop={handleDropZoneDrop}
      variableHeight
      allowMultiple={false}
      accept=".zip"
      disabled={disabled}
    >
      {uploadedFiles}
      {fileUpload}
    </DropZone>
  )
}

ImageDropZone.propTypes = {
  onFilesChange: PropTypes.func,
  disabled: PropTypes.bool
}

const UploadFromComputer = () => {
  const [isReady, setIsReady] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [progress, setProgress] = useState(0)
  const { shop, executionID } = useParams()
  const navigate = useNavigate()
  const uploadsRepo = new UploadsRepo(shop, API_URL)

  const handleFilesChange = async (files) => {
    setIsDisabled(true)
    await uploadsRepo.uploadFiles(executionID, files, setProgress)
    setIsReady(files.length > 0)
  }

  const handleInitLoad = async () => {
    await uploadsRepo.init(executionID)
    navigate(`/${shop}/upload/${executionID}/initialize`)
  }

  return (
    <Page
      breadcrumbs={[{ content: 'Products', onAction: () => { navigate(-1) } }]}
      primaryAction={{ content: 'Start upload', onAction: handleInitLoad, disabled: !isReady }}
      title="Create images upload"
    >
      <DisplayText size="small">
        <b>Important:</b>
        <p>We don&apos;t store your images, they are just cached in order to upload to your store.</p>
        <p>
          To match files with products the file name should be equal to product SKU. You can upload multiple files to
          a single product adding a number at the end of the file name.
        </p>
        <b>I.E:</b>
        <br/>
        <small>you want to add 3 images to a product with the SKU AAA-BBB-CCC</small>
        <br/>
        <small>then you add 3 images in the zip file with the following names:</small><br/>
        <small>AAA-BBB-CCC.png</small><br/>
        <small>AAA-BBB-CCC-2.png</small><br/>
        <small>AAA-BBB-CCC-3.png</small>
      </DisplayText>
      <br/>

      <ImageDropZone onFilesChange={handleFilesChange} disabled={isDisabled} ></ImageDropZone>
      <DisplayText size="small">Progress: {progress}%</DisplayText>
    </Page>

  )
}

export default UploadFromComputer
