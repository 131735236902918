// import logo from './logo.svg';
// import './App.css';
import '@shopify/polaris/build/esm/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import esTranslations from '@shopify/polaris/locales/es.json'
import { AppProvider } from '@shopify/polaris'

// import MainScreen from './screens/MainScreen'
import DriveLoadImagesScreen from './screens/DriveLoadImagesScreen'
import LoadScreen from './screens/LoadScreen'
import MainScreen from './screens/MainScreen'
import UploadTypeSelector from './screens/UploadTypeSelector'
import UploadFromComputer from './screens/UploadFromComputer'
import PartnerCode from './screens/PartnerCode'

const Root = () => (
  <BrowserRouter>
    <Routes>
      <Route
        exact
        path="/"
        element={<LoadScreen/>}
      />
      <Route
        exact
        path='/:shop/'
        element={<MainScreen/>}
      ></Route>
      <Route
        exact
        path='/:shop/partner'
        element={<PartnerCode/>}
      ></Route>
      <Route
        exact
        path='/:shop/upload/select-type'
        element={<UploadTypeSelector></UploadTypeSelector>}
      ></Route>
      <Route
        exact
        path='/:shop/upload/:executionID/load-files'
        element={<UploadFromComputer></UploadFromComputer>}
      ></Route>
      <Route
        exact
        path="/:shop/upload/:executionID/initialize"
        element={<DriveLoadImagesScreen/>}
      />
    </Routes>
  </BrowserRouter>
)

function App () {
  // const [searchParams] = useSearchParams()
  // console.log(searchParams)

  return (
    <AppProvider i18n={esTranslations}>
      <div className="App">
        <header className="App-header">
          <Root />
        </header>
      </div>
    </AppProvider>
  )
}

export default App
