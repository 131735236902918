import { v4 as uuidv4 } from 'uuid'

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000'

export const getUserID = () => {
  if (!localStorage.getItem('userID')) {
    localStorage.setItem('userID', uuidv4())
  }

  return localStorage.getItem('userID')
}

export const getShop = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const shop = searchParams.get('shop').split('.')[0]

  return shop
}
