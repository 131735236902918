import React, { useCallback } from 'react'

import { Page, Card, Grid, Badge } from '@shopify/polaris'
import { PropTypes } from 'prop-types'
import { useNavigate } from 'react-router'

function NonEmptyMainScreen ({ uploads, shop }) {
  const navigate = useNavigate()
  const formatDatetTime = (ISODate) => {
    return ISODate.split('.')[0].split('-').join('/').replace('T', ' - ')
  }

  const handleMainAction = useCallback(() => {
    navigate(`/${shop}/upload/select-type`)
  }, [])

  return (
    <Page
      title="Image Uploads"
      primaryAction={{
        content: 'Create new upload',
        onAction: handleMainAction
      }}
    >
      <Grid>
        {
          uploads.map((item, index) => {
            return (
              <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }} key={index}>
                <Card sectioned title={formatDatetTime(item.date)} actions={[{ content: 'see results', onAction: () => navigate(`/${shop}/upload/${item.id}/initialize`) }]} >
                  <Badge
                    status={ item.status === 'done' ? 'success' : 'warning' }
                    progress="complete"
                    statusAndProgressLabelOverride={
                      item.status === 'done'
                        ? 'Status: Completed. Your image upload is ready.'
                        : 'Status: In progress. Your image upload is on the way.'
                    }
                  >
                    { item.status }
                  </Badge>
                </Card>
              </Grid.Cell>
            )
          })
        }
      </Grid>
    </Page>
  )
}

NonEmptyMainScreen.propTypes = {
  uploads: PropTypes.array,
  shop: PropTypes.string
}

export default NonEmptyMainScreen
