import React, { useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { Page, Card, TextField } from '@shopify/polaris'
import { PropTypes } from 'prop-types'
import { API_URL } from '../services/globals'

function PartnerCode () {
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { shop } = useParams()

  const handleChange = (value) => {
    setValue(value)
  }

  const handleCodeValidation = async () => {
    setIsLoading(true)
    const response = await fetch(`${API_URL}/${shop}/partners/validate`, {
      method: 'get',
      headers: {
        'x-partner-code': value
      }
    })

    setIsLoading(false)
    if (response.status !== 401) {
      navigate(`/${shop}/upload/select-type`)
    }
  }

  return (
    <Page
      breadcrumbs={[{ content: 'Products', onAction: () => { navigate(-1) } }]}
      primaryAction={{ content: isLoading ? 'Verifiying' : 'Star uploading', onAction: handleCodeValidation }}
      title="Partner code"
    >
      <Card sectioned >
        {/* <DisplayText size="small">
          <p>Introduce your partner code.</p>
        </DisplayText>
        <br /> */}

        <TextField
          label="Introduce yout artner code to continue"
          value={value}
          onChange={handleChange}
          autoComplete="off"
        />
      </Card>
    </Page>
  )
}

PartnerCode.propTypes = {
  shop: PropTypes.string,
  validPayment: PropTypes.boolean,
  createPayment: PropTypes.func
}

export default PartnerCode
