
import { React, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { API_URL } from '../services/globals'
import { useParams } from 'react-router'
import UploadsRepo from '../services/uploadsrepo'

function withDriveLoadStatus (WrappedComponent) {
  const Component = (props) => {
    const { shop } = useParams()
    const [logs, setLogs] = useState([])
    const uploads = new UploadsRepo(shop, API_URL)
    const { executionID } = useParams()

    useEffect(() => {
      const tick = async () => {
        const json = await uploads.status(executionID)
        setLogs(json.logs)
      }

      const timeout = setTimeout(async () => {
        await tick()
      }, 500)

      return () => {
        clearTimeout(timeout)
      }
    }, [logs])

    return (<WrappedComponent {...props} logs={logs} />)
  }

  Component.propTypes = {
    match: PropTypes.object
  }

  return Component
}

export default withDriveLoadStatus
