import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'
import { getShop } from '../services/globals'

const LoadScreen = () => {
  const shop = getShop()
  const navigate = useNavigate()

  // https://carga-masiva-shopify-o7untflc2a-uc.a.run.app/shopify?
  // embedded=1&
  // hmac=2a3f3f51cb2079b858f052058cea1e7d381ec85034ed2a22949b5bae5202bd9c&
  // host=aW1hZ2V1cGxvYWRlci5teXNob3BpZnkuY29tL2FkbWlu&
  // locale=en-US&
  // session=232bba3e0e08a6dffd5cf34996dacddc4c1baf15e7ee4e8f0203f0b5aa18d59a&
  // shop=imageuploader.myshopify.com&
  // timestamp=1666702461

  useEffect(() => {
    // localStorage.setItem('shop', shop)
    navigate(`/${shop}`)
  }, [])

  return (<div>cargando...</div>)
}

export default LoadScreen
