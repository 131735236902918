import React, { useState } from 'react'

import { Card, EmptyState } from '@shopify/polaris'
import { useNavigate } from 'react-router'
import { PropTypes } from 'prop-types'

function EmptyMainScreen ({ shop, validPayment, createPayment }) {
  const navigate = useNavigate()
  const [generatingPayment, setGeneratingPayment] = useState(false)

  const handleClick = async () => {
    if (!validPayment) {
      setGeneratingPayment(true)
      await createPayment()
    } else {
      navigate(`/${shop}/upload/select-type`)
    }
  }

  const handlePartnerCode = async () => {
    console.log('partner code')
    navigate(`/${shop}/partner`)
  }

  return (
    <Card sectioned>
      <EmptyState
        heading="Manage image uploads"
        action={{
          content: validPayment
            ? 'Create new image upload'
            : generatingPayment
              ? 'redirecting...'
              : 'Proceed to payment before start',
          onAction: handleClick
        }}
        secondaryAction={{
          content: 'I have a partner code',
          onAction: handlePartnerCode
        }}
        image={
          validPayment
            ? 'https://cdn.loadingplay.com/static/images/200_ad525f5d9d1e275752c9af3f2ace2e27_empty_no_filet.png'
            : 'https://cdn.loadingplay.com/static/images/200_f60153619ca8d00aa0297317a3da1bd7_empty_payment.png'
        }
      >
        <p>Manage massive image uploads for your Shopify products using SKU for pairing the image name with product/variant.</p>
        {
          validPayment
            ? null
            : <p>You will be charged one time to start your image upload.</p>
        }
      </EmptyState>
    </Card>
  )
}

EmptyMainScreen.propTypes = {
  shop: PropTypes.string,
  validPayment: PropTypes.boolean,
  createPayment: PropTypes.func
}

export default EmptyMainScreen
