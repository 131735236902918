import React from 'react'
// import { React, useState, useCallback } from 'react'
// import { withRouter } from 'react-router'
// import { Page, Card, Layout, PageActions } from '@shopify/polaris'

// import styled from 'styled-components'

// import { DriveIntegration } from '../components/driveintegration'
// import { ShopifyConfig } from '../components/shopifyconfig'

// import { withConfigLoader } from '../hoc/withconfigloader'
// import withDriveLogin from '../hoc/withdrivelogin'
// import withDriveDirectories from '../hoc/withdrivedirectories'

// import { DriveConfigRepo } from '../services/driveconfigrepo'
// import { API_URL } from '../services/globals'
// import { API_URL, getUserID } from '../services/globals'
// import LoadRepo from '../services/loadrepo'

import PropTypes from 'prop-types'
import EmptyMainScreen from './EmptyMainScreen'
import withUploadsLoader from '../hoc/withuploadsloader'
import NonEmptyMainScreen from './NonEmptyMainScreen'
import { useParams } from 'react-router'

// const CardHeight = styled.div`height: 300px;`

const MainScreen = ({ isLoading, uploads, validPayment, createPayment }) => {
  const { shop } = useParams()

  return (
    <>
      { isLoading
        ? 'cargando'
        : (!validPayment
            ? <EmptyMainScreen shop={shop} validPayment={validPayment} createPayment={createPayment} ></EmptyMainScreen>
            : <NonEmptyMainScreen shop={shop} uploads={uploads} ></NonEmptyMainScreen>
          )
      }
    </>
  )
  // const [shopifyConfig, setShopifyConfig] = useState({})
  // const handleShopifyConfigChange = (shopifyConfig) => {
  //   setShopifyConfig(shopifyConfig)
  // }

  // const [driveConfig, setDriveConfig] = useState({ ...props.driveConfig })
  // const onDirectorySelected = useCallback(
  //   (selectedDirectory) => {
  //     setDriveConfig({
  //       ...props.driveConfig, selectedDirectory
  //     })
  //   }, [props.driveConfig])

  // const onAction = async () => {
  //   const jsonData = await loadRepo.init(shopifyConfig, driveConfig)
  //   props.history.push(`/drive/load-images/${jsonData.id}`)
  // }

  // const loadRepo = new LoadRepo(API_URL, getUserID())

  // return (
  //   <Page
  //     title='Crear una nueva carga de imágenes'
  //   >
  //     <Layout>
  //       <Layout.Section oneThird >
  //         <Card title="Google Drive" sectioned={true} >
  //           <CardHeight>
  //             <DriveIntegration
  //               directories={props.directories}
  //               selectedDirectory={driveConfig.selectedDirectory}
  //               onDirectorySelected={onDirectorySelected}
  //               onLogin={props.onLogin}
  //             ></DriveIntegration>
  //           </CardHeight>
  //         </Card>
  //       </Layout.Section>
  //       <Layout.Section oneThird >
  //         <Card title="Conexión con Shopify" sectioned={true} >
  //           <CardHeight>
  //             <ShopifyConfig
  //               config={shopifyConfig}
  //               onChange={handleShopifyConfigChange}
  //             ></ShopifyConfig>
  //           </CardHeight>
  //         </Card>
  //       </Layout.Section>
  //     </Layout>
  //     <PageActions
  //       primaryAction={{
  //         content: 'Iniciar carga de imágenes',
  //         onAction
  //       }}
  //     />
  //   </Page>
  // )
}

MainScreen.propTypes = {
  isLoading: PropTypes.bool,
  uploads: PropTypes.array,
  validPayment: PropTypes.bool,
  createPayment: PropTypes.func
}

// export default
// withRouter(
//   withDriveLogin(
//     withDriveDirectories(
//       withConfigLoader(MainScreen, new DriveConfigRepo(API_URL))
//     )
//   )
// )
export default withUploadsLoader(MainScreen)
